import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
	primary: colors.common.lightBlue,
    secondary: colors.common.pink,
    text: {
      primary: '#0d47a1',
      secondary: '#0d47a1'
    }
  },
  shadows,
  typography
});

export default theme;
