import React, {Component} from 'react';
import axios from 'axios';

import {
	Avatar,
	Card,
	CardContent,
	Grid,
	Typography,
	Button,
	IconButton,
	TextField,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemSecondaryAction,
	colors,
	withStyles
} from '@material-ui/core';

import DnsIcon from '@material-ui/icons/Dns';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = theme => ({
	root: {
		height: '100%'
	},
	input: {
		width: "100%"
	}, 
	bottomSpace: {
		marginBottom: theme.spacing(4)
	},
	avatar: {
		backgroundColor: colors.red[600],
		height: 56,
		width: 56
	},
	differenceIcon: {
		color: colors.red[900]
	},
	differenceValue: {
		color: colors.red[900],
		marginRight: theme.spacing(1)
	},
	ipsList: {
		fontFamily: "Roboto", 

		"& ul": {
			backgroundColor: theme.palette.background.paper,
			border: "1px solid #ccc",
			padding: theme.spacing(2) + "px " + theme.spacing(2) / 2 + "px",
			maxHeight: "535px",
			overflowY: "auto"
		}
	},
	formWrap: {
		minHeight: "600px", 
		padding: "30px"
	},
	formBtn: {
		height: "56px",
		display: "block",
		width: "100px"
	}
});

class HomePageForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			isLoaded: false,
			ips: [],
			ipfieldval: "",
			formError: false,
			formErrorMessage: ""
		};

		this.onHandleChange = this.onHandleChange.bind(this);
	}

	// On Load
	async componentDidMount() {
		try {
			this.getIps();
		} catch (error) {
			this.setState({isLoaded: true, error: error});
		}
	}

	// Requests
	async getIps() {
		const response = await axios.post('https://newsfortoday.club/webapi/?method=GetAllIPInstances');
		if (response.data.Status === "ok") {
			let ips = JSON.parse(response.data.Params);

			if (typeof ips !== 'undefined' && ips.length !== 0) {
				this.setState({isLoaded: true, ips: ips});
			} else {
				this.setState({isLoaded: true, ips: []});
			}
		} else {
			this.setState({isLoaded: true, ips: [], error: "Request Error"});
		}
	}

	// Handles
	onHandleChange(e) {
		this.setState({ipfieldval: e.target.value});
	}

	onHandleRemoveIp = ipID => async e => {
		e.preventDefault();
		
		try {
			const response = await axios.post('https://newsfortoday.club/webapi/?method=DeleteIPInstance', {"IPId": ipID});
			
			if (response.data.Status === "ok") {
				this.getIps();
				this.setState({
					ipfieldval: '', 
					formError: false, 
					formErrorMessage: ''
				});
			} else {
				this.setState({
					formError: true, 
					formErrorMessage: 'Request Error'
				});
			}
		} catch (error) {
			// this.setState({error: error});
		}
	}
	
	onHandleAddNewIpSubmit = async e => {
		e.preventDefault();
		try {
			const ipval = e.target.AddNewIp.value;
			
			if (this.ValidateIPaddress(ipval)) {
				const response = await axios.post('https://newsfortoday.club/webapi/?method=InsertIPInstance', {"IP": ipval});
				
				if (response.data.Status === "ok") {
					this.getIps();
					this.setState({
						ipfieldval: '', 
						formError: false, 
						formErrorMessage: ''
					});
				} else {
					this.setState({
						formError: true, 
						formErrorMessage: 'Request Error'
					});
				}
			} else {
				this.setState({
					formError: true, 
					formErrorMessage: 'Not a valid IP'
				});
			}
		} catch (error) {
			// this.setState({isLoaded: true, error: error});
		}
	}

	// Renders
	renderIps(error, isLoaded, ips) {
		const {classes} = this.props;

		if (isLoaded && ips.length !== 0) {
			return ips.map(ip => (<ListItem key={ip.IPInstanceId}>
				<ListItemAvatar>
					<Avatar>
						<DnsIcon/>
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary={ip.IP}/>
				<ListItemSecondaryAction>
					<div className={classes.deletebtn + " deletebtn"} onClick={this.onHandleRemoveIp(ip.IPInstanceId)}>
						<IconButton edge="end" aria-label="delete">
							<DeleteIcon/>
						</IconButton>
					</div>
				</ListItemSecondaryAction>
			</ListItem>))
		} else {
			return <ListItem>No IPs</ListItem>
		}
	}

	// Other
	ValidateIPaddress(ipaddress) {
		if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
			return (true)
		}
		return (false)
	}

	// VIew
	render() {
		const {classes} = this.props;
		const {error, isLoaded, ips, ipfieldval, formError, formErrorMessage} = this.state;

		return (<Card>
			<CardContent>
				<Grid container justify="flex-start" spacing={5} className={classes.formWrap}>
					<Grid item xs={12} cm={12} md={6}>
						<form className={classes.root} noValidate autoComplete="off" onSubmit={this.onHandleAddNewIpSubmit}>
							<Typography className={classes.bottomSpace} color="textSecondary" variant="h5">
								ADD NEW IP
							</Typography>
							<Grid container justify="flex-start" spacing={3}>
								<Grid item xs>
									<TextField
									className={classes.input}
									id="AddNewIp"
									label="Add New IP" 
									variant="outlined" 
									value={ipfieldval} onChange={this.onHandleChange}
									error={formError}
									helperText={formErrorMessage}
								/>
								</Grid>
								<Grid item>
									<Button type="submit" className={classes.formBtn} variant="contained" color="primary">
										ADD
									</Button>
								</Grid>
							</Grid>
						</form>
					</Grid>
					<Grid item xs={12} cm={12} md={6}>
						<div className={classes.ipsList}>
							<Typography className={classes.bottomSpace} color="textSecondary" variant="h5">
								ALLOWED IPS:
							</Typography>
							<List>
								{this.renderIps(error, isLoaded, ips)}
							</List>
						</div>
					</Grid>
				</Grid>
			</CardContent>
		</Card>);
	}
};

export default withStyles(useStyles)(HomePageForm);