import React, { useEffect, useState, useRef } from "react";
import MaterialTable from 'material-table';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

import {
	Button, 
	Card,
	CardContent,
	Grid,
} from '@material-ui/core';

const apiUrl = 'https://newsfortoday.club/webapi';

const useStyles = makeStyles({
	imgUploadBtn: {
		display: 'none',
		margin: '0 0 20px',
	}, 
	imgPreview: {
		width: '100px',
		height: '100px',
		fontSize: '14px',
		lineHeight: '100px',
		textAlign: 'center',
		background: '#ccc',
		color: '#fff',
		borderRadius: '8px',
		overflow: 'hidden',
		'& img' : {
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		},
	}
});

export default function ManageOffers(){
	const classes = useStyles();
	
	const chosenImage = useRef(null);
	
	const [loading, setLoading] = useState(true);
	const [loaded, setLoaded] = useState(true);

	const [entries, setEntries] = useState([]);

	const [tableCurrentPage, setTableCurrentPage] = useState([0]);
	const [tablePerPage, setTablePerPage] = useState([5]);

	const columns = [
		{
			title: 'ID',
			field: 'offerId',
			editable: 'never',
			readonly: true,
			align: 'left',
			headerStyle: { paddingRight: '60px' },
		},
		{
			title: 'Title',
			field: 'name',
			headerStyle: { paddingRight: '120px' },
			validate: (rowData) => (!rowData.name || rowData.name === ' ') ? 'Field can\'t be empty' : true,
		},
		{
			title: 'Logo',
			field: 'imgId',
			sorting: false,
			filtering: false,
			editable: 'always',
			editComponent: (props) => (
				<div value="photo">
					<input
						accept="image/*"
						style={{ display: 'none' }}
						id="raised-button-file"
						type="file"
						ref={ chosenImage }
						onChange={(e) => props.onChange(e.target.value)} />
					<label htmlFor="raised-button-file">
						<Button variant="contained" component="span">Upload</Button>
					</label>
				</div>
			),
			render: (rowData) => (
				<div className={classes.imgPreview}>
					{
						(rowData.img === undefined || rowData.img.length) ?
							(loaded ? 'Loading...' : (<img alt={rowData.name} title={rowData.name} src={rowData.img} />)) :
							('No Image')
					}
				</div>
			),
			validate: (rowData) => {
				if (!rowData.tableData) {
					if (chosenImage.current === null || !chosenImage.current.files.length) {
						return 'Please choose Logo image';
					} else {
						return true;
					}
				} else {
					return true;
				}
			},
		},
		{
			title: 'Text',
			field: 'text',
			headerStyle: { paddingRight: '150px' },
			validate: (rowData) => (!rowData.text || rowData.text === ' ') ? 'Field can\'t be empty' : true,
		},
		{
			title: 'Link',
			field: 'link',
			headerStyle: { paddingRight: '150px' },
			validate: (rowData) => (!rowData.link || rowData.link === ' ') ? 'Field can\'t be empty' : true,
		},
		{
			title: 'Link Name',
			field: 'linkName',
			headerStyle: { paddingRight: '100px' },
			validate: (rowData) => (!rowData.linkName || rowData.linkName === ' ') ? 'Field can\'t be empty' : true,
		},
		{
			title: 'Comment 1',
			field: 'Comment1',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 2',
			field: 'Comment2',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 3',
			field: 'Comment3',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 4',
			field: 'Comment4',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 5',
			field: 'Comment5',
			headerStyle: { paddingRight: '120px' },
		},
	];

	const tableOptions = {
		filtering: true,
		pageSize: 5,
		pageSizeOptions: [5, 10, 20, 50, 100],
		addRowPosition: 'first',
		doubleHorizontalScroll: true,
		headerStyle: {
			fontFamily: 'Roboto',
			whiteSpace: 'nowrap',
		},
		rowStyle: {
			fontFamily: 'Roboto',
		},
	};

	useEffect(() => {
		axios
			.get(`${apiUrl}/?method=GetAllOffers`)
			.then((response) => {
				if (response.data.Status === 'ok') {
					let items = JSON.parse(response.data.Params);
					
					if (typeof items !== 'undefined' && items.length !== 0) {
						return items.reverse();
					} else {
						return [];
					}
				}
			}).then((items) => {
				if (!items.length) {
					return;
				}

				const newItems = items.map((item) => {
					const fieldsToCheckArray = [
						'name',
						'text',
						'link',
						'linkName',
						'Comment1',
						'Comment2',
						'Comment3',
						'Comment4',
						'Comment5',
					];

					fieldsToCheckArray.forEach((field) => {
						if (item[field] === null) {
							item[field] = '';
						}
					});

					return item;
				});

				setEntries(newItems);

				loadItemsImages(newItems);
			})
	}, []);

	useEffect(() => {
		loadItemsImages(entries);
	}, [tableCurrentPage, tablePerPage]);

	const loadItemsImages = (newItems) => {
		setLoaded(true);
		setLoading(true);

		let images = {};
		let itemsPromises = [];

		const pageGap = tableCurrentPage * tablePerPage;

		const dynamicItems = newItems.slice(pageGap, pageGap + tablePerPage);

		dynamicItems.forEach((item) => {
			if (item.img === undefined) {
				itemsPromises.push(
					axios.post(`${apiUrl}/?method=GetFile`, { fileId: item.logoPictureFileId })
				);
			}
		});

		if (!itemsPromises.length) {
			setLoading(false);
			setLoaded(false);

			return;
		}

		Promise.all(itemsPromises).then(data => {
			data.forEach((item) => {
				if (item.data.Status === 'ok') {
					const itemData = JSON.parse(item.data.Params);

					return images[itemData.fileId] = itemData;
				}
			});
		}).then(() => {
			let itemsParsed = [];

			dynamicItems.forEach((item) => {
				let currentItem = {...item};

				if (images[ item.logoPictureFileId ]) {
					const currentImg = images[ item.logoPictureFileId ];

					currentItem.img = `data:${currentImg.fileType};base64, ${currentImg.dataUrl}`;
					currentItem.imgName = currentImg.fileName;
					currentItem.imgType = currentImg.fileType;
				}

				itemsParsed.push(currentItem);
			});

			let prevItems = [];

			if (pageGap > 0) {
				prevItems = newItems.slice(0, pageGap);
			}

			const staticItems = newItems.slice(pageGap + tablePerPage);

			setEntries([...prevItems, ...itemsParsed, ...staticItems]);
			setLoading(false);
			setLoaded(false);
		});
	};

	const addNewRowHandler = (newData) => new Promise((resolve) => {
		setTimeout(() => {
			let rowData = {...newData};

			const files = chosenImage.current.files;

			if (files.length) {
				const reader = new FileReader();
				const file = files[0];

				reader.readAsDataURL(file);
				reader.onloadend = (e) => {
					const sendData = {...rowData};

					sendData.dataUrl = reader.result;
					sendData.imgFileName = file.name;
					sendData.imgFileType = file.type;

					rowData.offerId = (entries.length) ? (entries[0].offerId + 1) : 1;
					rowData.img = sendData.dataUrl;
					rowData.imgName = sendData.imgFileName;
					rowData.imgType = sendData.imgFileType;

					setEntries([rowData, ...entries]);

					axios
						.post(`${apiUrl}/?method=InsertOffer`, sendData)
						.then(res => console.log(res.data));

					resolve();
				};
			}
		}, 200);
	});

	const editNewRowHandler = (newData, oldData) => new Promise(resolve => {
		setTimeout(() => {
			let rowData = {...newData};

			let sendData = {
				offerId: oldData.offerId,
				name: rowData.name,
				text: rowData.text,
				link: rowData.link,
				linkName: rowData.linkName,
				dataUrl: rowData.img,
				imgFileName: rowData.imgName,
				imgFileType: rowData.imgType,
				Comment1: rowData.Comment1,
				Comment2: rowData.Comment2,
				Comment3: rowData.Comment3,
				Comment4: rowData.Comment4,
				Comment5: rowData.Comment5,
			};

			const data = [...entries];

			const reader = new FileReader();
			const file = chosenImage.current.files[0];

			if (file) {
				reader.readAsDataURL(file);
				reader.onloadend = (e) => {
					rowData.img = reader.result;

					sendData.dataUrl = reader.result;
					sendData.imgFileName = file.name;
					sendData.imgFileType = file.type;

					axios
						.post(`${apiUrl}/?method=EditOffer`, sendData)
						.then((res) => console.log(res.data));

					data[data.indexOf(oldData)] = rowData;

					setEntries(data);

					resolve();
				};
			} else {
				axios
					.post(`${apiUrl}/?method=EditOffer`, sendData)
					.then((res) => console.log(res.data));
	
				data[data.indexOf(oldData)] = rowData;
	
				setEntries(data);
	
				resolve();
			}
		}, 200);
	});

	const deleteRowHandler = (oldData) => new Promise(resolve => {
		setTimeout(() => {
			const data = [...entries];

			data.splice(data.indexOf(oldData), 1);

			axios
				.post(`${apiUrl}/?method=DeleteOffer`, { offerId: oldData.offerId })
				.then((res) => console.log(res.data));

			setEntries(data);

			resolve();
		}, 200);
	});

	const onPageChangeHandler = (pageNumber, rowsNumber) => {
		if (pageNumber !== tableCurrentPage) {
			setTableCurrentPage(pageNumber);
		}

		if (rowsNumber !== tablePerPage) {
			setTablePerPage(rowsNumber);
		}
	};

	const loadAllImagesHandler = () => setTablePerPage(1000);

	return ( <Card>
		<CardContent>
			<Grid container justify="flex-start" spacing={5}>
				<Grid item xs={12}>
					<div style={{ maxWidth: '100%' }}>
						<MaterialTable
							title = 'Manage Offers'
							columns = { columns }
							data = { entries }
							options = { tableOptions }
							isLoading = { loading }
							editable = {{
								onRowAdd: addNewRowHandler,
								onRowUpdate: editNewRowHandler,
								onRowDelete: deleteRowHandler,
							}}
							onChangePage = { onPageChangeHandler }
							onOrderChange = { loadAllImagesHandler }
							onSearchChange = { loadAllImagesHandler }
							onFilterChange = { loadAllImagesHandler }
						/>
					</div>
				</Grid>
			</Grid>
		</CardContent>
	</Card>);
}