import React from 'react';
import {Container, makeStyles} from '@material-ui/core';
import Page from 'src/components/Page';
import ManageArticlesForm from './ManageArticlesForm';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: '100%',
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	wrap: {
		maxWidth: "100%",
	}
}));

const ManageArticles = () => {
	const classes = useStyles();

	return (<Page className={classes.root} title="Manage Articles">
		<Container className={classes.wrap}>
			<ManageArticlesForm/>
		</Container>
	</Page>);
};

export default ManageArticles;
