import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import axios from 'axios';

import {
	Avatar,
	Button,
	Card,
	CardContent,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	// ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	// MenuItem,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';

import {
	// Inbox as InboxIcon,
	Delete as DeleteIcon,
	Dns as DnsIcon,
} from '@material-ui/icons';

const apiUrl = 'https://newsfortoday.club/webapi';

const useStyles = (theme) => ({
	infoBlock: {
		padding: '0 !important', 
		fontFamily: "Roboto"
	}, 
	infoBlockRed: {
		border: "1px solid #ff0000",
		color: "#ff0000", 
		margin: "12px 12px 20px",
		padding: "20px", 
		
		"&:empty": {
			display: "none",
			visibility: "hidden",
		}
	},
	infoBlockGreen: {
		border: "1px solid #4ec522",
		color: "#4ec522", 
		margin: "12px 0 20px",
		padding: "20px", 
		
		"&:empty": {
			display: "none",
			visibility: "hidden",
		}
	},
	input: {
		width: "100%", 
		marginBottom: "20px"
	}, 
	inputSpace: {
		width: "100%",
		marginBottom: theme.spacing(2)
	}, 
	bottomSpace: {
		marginBottom: theme.spacing(4)
	},
	bottomSpaceSmall: {
		marginBottom: theme.spacing(2)
	},
	createTemplateForm: {
		fontFamily: "Roboto", 
		backgroundColor: theme.palette.background.paper,
		border: "1px solid #ccc",
		padding: "30px",
	},
	formWrap: {
		paddingBottom: "40px",

		"& .MuiGrid-item": {
			width: "100%"
		}
	},
	templatesList: {
		flexWrap: "wrap", 
		display: "flex", 
		margin: "0 0 30px",

		"& > li": {
			margin: "0 10px 10px 0",
			border: "1px solid #ccc",
			textAlign: "left", 
		},
		"& .MuiListItemIcon-root": {
			minWidth: "36px", 
		}
	}, 
	mainList: {
		margin: "0 0 40px",
		fontFamily: "Roboto", 
		backgroundColor: theme.palette.background.paper,
		border: "1px solid #ccc",
		padding: "20px",
		maxHeight: "650px",
		overflowY: "auto", 
	},
	foldersList: {
		margin: "0 0 20px",
		fontFamily: "Roboto", 
		backgroundColor: theme.palette.background.paper,
		border: "1px solid #ccc",
		padding: "20px",
		maxHeight: "450px",
		overflowY: "auto", 
		
		"& label": {
			margin: 0
		}, 
		"& .MuiTextField-root": {
			margin: "20px 0 0"
		}
	},
	formBtn: {
		height: "46px",
		display: "block",
		width: "100%",
		margin: "20px 0 20px",

		"& span": {
			lineHeight: "36px", 
			textAlign: "center",
		}
	}, 
});

function ManageOffersWebsitesForm(props) {
	const { classes } = props;

	const [entries, setEntries] = useState({
		websites: [],
		lists: [],
		sliders: [],
	});
	const [fields, setFields] = useState({
		name: '',
		template: 1,
		list: null,
		foldersList: null,
		folderName: '',
		sliders: [],
	});
	const [folders, setFolders] = useState({});
	const [folderErrors, setFolderErrors] = useState([]);
	const [errors, setErrors] = useState([]);
	const [loading, setLoading] = useState(true);
	const [isVisible, setVisibility] = useState(false);
	const [reload, setReload] = useState(false);

	const columns = [
		{
			title: 'ID',
			field: 'websiteId',
			editable: 'never',
			readonly: true,
			align: 'left',
			headerStyle: { paddingRight: '60px' },
		},
		{
			title: 'Name',
			field: 'name',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 1',
			field: 'Comment1',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 2',
			field: 'Comment2',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 3',
			field: 'Comment3',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 4',
			field: 'Comment4',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 5',
			field: 'Comment5',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 6',
			field: 'Comment6',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 7',
			field: 'Comment7',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Template',
			field: 'templateName',
			headerStyle: { paddingRight: '120px' },
			render: (rowData) => (
				<>
					{
						(Number(rowData.templateName) === 15) ? "Tabs" : (Number(rowData.templateName) === 16) ? "Squares" : "Template " + rowData.templateName
					}
				</>
			),
		},
		{
			title: 'Main Page List',
			field: 'mainPageOffersListId',
			headerStyle: { paddingRight: '120px' },
			render: (rowData) => (
				<>
					{rowData.mainPageOffersList && rowData.mainPageOffersList.offersListName}
				</>
			),
		},
		{
			title: 'Sliders',
			field: 'sliders',
			sorting: false,
			headerStyle: { paddingRight: '150px' },
			render: (rowData) => (
				<>
					{
						rowData.sliders[0] !== null && rowData.sliders ?
							rowData.sliders
								.map((item) => item.name)
								.join(', ') :
							''
					}
				</>
			),
		},
		{
			title: 'Folders',
			field: 'folders',
			sorting: false,
			headerStyle: { paddingRight: '150px' },
			render: (rowData) => (
				<>
					{
						rowData.folders ?
							rowData.folders
								.map((item) => item.folderName)
								.join(', ') :
							''
					}
				</>
			),
		},
	];

	const tableOptions = {
		filtering: true,
		pageSize: 5,
		pageSizeOptions: [5, 10, 20, 50, 100],
		loadingType: 'linear',
		// addRowPosition: 'first',
		doubleHorizontalScroll: true,
		headerStyle: {
			fontFamily: 'Roboto',
			whiteSpace: 'nowrap',
		},
		rowStyle: {
			fontFamily: 'Roboto',
		},
	};

	useEffect(() => {
		const fetchData = async () => {
			const websites = await axios.get(`${apiUrl}/?method=GetAllWebsites`);
			const lists = await axios.get(`${apiUrl}/?method=GetAllOffersLists`);
			const sliders = await axios.get(`${apiUrl}/?method=GetAllSliders`);

			const websitesData = (websites.data.Status === 'ok') ? JSON.parse(websites.data.Params).reverse() : [];
			const listsData = (lists.data.Status === 'ok') ? JSON.parse(lists.data.Params).reverse() : [];
			const slidersData = (sliders.data.Status === 'ok') ? JSON.parse(sliders.data.Params).reverse() : [];

			if (websitesData.length || listsData.length || slidersData.length) {
				setEntries({
					websites: websitesData.filter((website) => !website.isArticles),
					lists: listsData,
					sliders: slidersData.filter((slider) => slider.offerId !== -1),
				});

				if (listsData.length) {
					setFields((prevFields) => {
						return {
							...prevFields,
							list: listsData[0].offersListId,
							foldersList: listsData[0].offersListId,
						};
					});
				}
			}

			setLoading(false);
		};

		fetchData();
	}, [reload]);

	const onHandleChange = (e, field) => {
		let value = '';

		switch (field) {
			case 'name':
			case 'folderName':
			case 'Comment1':
			case 'Comment2':
			case 'Comment3':
			case 'Comment4':
			case 'Comment5':
			case 'Comment6':
			case 'Comment7':
				value = e.target.value;

				break;
			case 'sliders':
				let sliders = [Number(e.target.value)];

				if (fields[field]) {
					sliders = [...fields[field], Number(e.target.value)];

					if (fields[field].includes(Number(e.target.value))) {
						sliders = [...fields[field]].filter((item) => item !== Number(e.target.value));
					}
				}

				value = sliders;

				break;
			default:
				value = Number(e.target.value);
		}

		setFields({ ...fields, [field]: value });
	};

	const onHandleSubmit = async (e) => {
		e.preventDefault();

		const fieldsErrors = [];

		if (!fields.name.trim().length) {
			fieldsErrors.push('Website Name is empty');
		}
		
		if (!fields.template) {
			fieldsErrors.push('Template not selected');
		}
		
		if (!fields.list) {
			fieldsErrors.push('Offers List not selected');
		}
		
		if (!fields.sliders.length) {
			fieldsErrors.push('Sliders not selected');
		}

		if (!fieldsErrors.length) {
			const foldersList = [];

			if (Object.keys(folders).length) {
				for (const [name, value] of Object.entries(folders)) {
					foldersList.push({
						folderName: name.toString(),
						offersListId: Number(value),
					});
				}
			}

			const createWebsite = async () => {
				const response = await axios.post(`${apiUrl}/?method=CreateWebsite`, {
					"websiteName": fields.name.trim(),
					"templateName": fields.template.toString(),
					"sliderImageIds": fields.sliders,
					"mainPageOffersListId": fields.list,
					"folders": foldersList,
					"isArticle": false,
					"Comment1": fields.Comment1,
					"Comment2": fields.Comment2,
					"Comment3": fields.Comment3,
					"Comment4": fields.Comment4,
					"Comment5": fields.Comment5,
					"Comment6": fields.Comment6,
					"Comment7": fields.Comment7,
				});

				console.log(response.data);

				if (response.data.Status === 'ok') {
					setFields({
						name: '',
						template: 1,
						list: null,
						foldersList: null,
						folderName: '',
						sliders: [],
					});
					setFolders({});
					setErrors([]);
					setVisibility(false);
					setLoading(true);
					setReload(!reload);
				} else {
					setErrors(['Request Error']);
				}
			};

			createWebsite();
		}

		setErrors(fieldsErrors);
	};

	const onHandleCreateFolder = (e) => {
		e.preventDefault();

		const folderErrors = [];

		if (!fields.folderName.trim().length) {
			folderErrors.push('Folder name is empty.');
		}

		if (!fields.foldersList) {
			folderErrors.push('Folder item isn\'t set.');
		}

		if (folderErrors.length) {
			setFolderErrors(folderErrors);
		} else {
			setFolders({ ...folders, [fields.folderName.trim()]: fields.foldersList });
			setFields({
				...fields,
				folderName: '',
				foldersList: entries.lists[0].offersListId,
			});
			setFolderErrors([]);
		}
	}

	const onHandleRemoveFolder = (e, name) => {
		e.preventDefault();

		setFolders(Object.fromEntries(Object.entries(folders).filter(([key, value]) => key !== name)));
	}

	// const renderTemplatesList = () => {
	// 	const templatesList = [1, 2, 3, 15, 16];

	// 	return (templatesList.map((templatesListItem) => {
	// 		let templateName = "Template " + templatesListItem;

	// 		if (templatesListItem === 15) {
	// 			templateName = "Tabs";
	// 		}

	// 		if (templatesListItem === 16) {
	// 			templateName = "Squares";
	// 		}

	// 		return (
	// 			<MenuItem 
	// 				key={templatesListItem}
	// 				button 
	// 				selected={fields.template === templatesListItem}
	// 				onClick={() => setFields({ ...fields, template: templatesListItem })}>
	// 				<ListItemIcon>
	// 					<InboxIcon />
	// 				</ListItemIcon>
	// 				<div>
	// 					<ListItemText primary={templateName} />
	// 				</div>
	// 			</MenuItem>
	// 		);
	// 	}))
	// };

	const renderOffersListsItems = (field) => {
		if (!loading && entries.lists.length !== 0) {
			return (
				entries.lists.map((list) => {
					return (
						<FormControlLabel
							key={field + list.offersListId}
							value={list.offersListId}
							control={<Radio />}
							label={list.offersListName} />
					);
				})
			)
		} else {
			return <p>No Offers Lists</p>
		}
	}

	const renderSliders = () => {
		if (!loading && entries.sliders.length !== 0) {
			return (
				entries.sliders.map((slider) => {
					return (
						<FormControlLabel
							key={`sliders${slider.sliderId}`}
							value={slider.sliderId}
							control={<Checkbox checked={fields.sliders.includes(slider.sliderId) ? true : false} />}
							label={slider.name} />
					);
				})
			)
		} else {
			return <p>No Sliders</p>
		}
	}

	const renderFolders = () => {
		if (Object.keys(folders).length !== 0) {
			const items = [];

			for ( const [name, value] of Object.entries( folders ) ) {
				let offersListId = Number(value); // Remove all except numbers
				let offersListName = '';

				if (entries.lists.length !== 0) {
					entries.lists.forEach((offersList) => {
						if (offersList.offersListId === offersListId) {
							offersListName = offersList.offersListName;
						}
					})
				}

				items.push(<ListItem key={name}>
					<ListItemAvatar>
						<Avatar>
							<DnsIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary={name + " (" + (offersListName !== '' ? offersListName : offersListId) + ")"}/>
					<ListItemSecondaryAction>
						<div onClick={(e) => onHandleRemoveFolder(e, name)}>
							<IconButton edge="end" aria-label="delete">
								<DeleteIcon/>
							</IconButton>
						</div>
					</ListItemSecondaryAction>
				</ListItem>)
			}

			return (
				<List>
					{items.map((value) => value)}
				</List>
			)
		} else {
			return <p>No Folders</p>
		}
	}

	return ( <Card>
		<CardContent>
			<Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={0} className={classes.formWrap} style={{display: (isVisible ? 'block' : 'none')}}>
				<Grid item>
					<form className={classes.createTemplateForm} noValidate autoComplete="off" onSubmit={onHandleSubmit}>
						<Grid container justify="flex-start" alignItems="center" spacing={6} className={classes.bottomSpaceSmall}>
							<Grid item md={12} style={{ textAlign: 'right' }}>
								<Button variant="contained" color='default' onClick={() => setVisibility(!isVisible)}>CANCEL</Button>
							</Grid>
						</Grid>
						{/* <TextField
							label="Website Name"
							value={fields.name}
							onChange={(e) => onHandleChange(e, 'name')} 
							className={classes.input} 
							variant="outlined" />
						<div className={classes.templatesList}>
							{renderTemplatesList()}
						</div> */}
						<Grid container justify="flex-start" alignItems="flex-start" spacing={6} style={{ marginBottom: '10px' }}>
							<Grid item xs={12} cm={12} md={9}>
								<TextField
									label="Website Name"
									value={fields.name}
									onChange={(e) => onHandleChange(e, 'name')} 
									className={classes.input} 
									variant="outlined" />
							</Grid>
							<Grid item xs={12} cm={12} md={3}>
								<TextField 
									label="Website Template" 
									type="number" 
									InputProps={{ inputProps: { min: 1, max: 1000 } }} 
									value={fields.template} 
									onChange={(e) => onHandleChange(e, 'template')} 
									className={classes.input} 
									variant="outlined" />
							</Grid>
						</Grid>
						<Grid container justify="flex-start" alignItems="flex-start" spacing={6} style={{ marginBottom: '10px' }}>
							<Grid item xs={12} cm={12} md={6}>
								<Typography className={classes.bottomSpaceSmall} color="textSecondary" variant="h6">
									CHOOSE MAIN PAGE LIST:
								</Typography>
								<div className={classes.mainList}>
									<RadioGroup aria-label="list" name="list" value={(fields.list !== undefined ? fields.list : '')} onChange={(e) => onHandleChange(e, 'list')}>
										{renderOffersListsItems('list')}
									</RadioGroup>
								</div>
								<Typography className={classes.bottomSpaceSmall} color="textSecondary" variant="h6">
									CHOOSE SLIDERS:
								</Typography>
								<div className={classes.mainList}>
									<FormGroup aria-label="sliders" name="sliders" value={(fields.sliders !== undefined ? fields.sliders : [])} onChange={(e) => onHandleChange(e, 'sliders')}>
										{renderSliders()}
									</FormGroup>
								</div>
							</Grid>
							<Grid item xs={12} cm={12} md={6}>
								<Typography className={classes.bottomSpaceSmall} color="textSecondary" variant="h6">
									ADD COMMENTS:
								</Typography>
								<TextField 
									label="Comment 1" 
									value={(fields["Comment1"] !== undefined ? fields["Comment1"] : '')} 
									onChange={(e) => onHandleChange(e, 'Comment1')} 
									className={classes.inputSpace} 
									variant="outlined" />
								<TextField 
									label="Comment 2" 
									value={(fields["Comment2"] !== undefined ? fields["Comment2"] : '')} 
									onChange={(e) => onHandleChange(e, 'Comment2')} 
									className={classes.inputSpace} 
									variant="outlined" />
								<TextField 
									label="Comment 3" 
									value={(fields["Comment3"] !== undefined ? fields["Comment3"] : '')} 
									onChange={(e) => onHandleChange(e, 'Comment3')} 
									className={classes.inputSpace} 
									variant="outlined" />
								<TextField 
									label="Comment 4" 
									value={(fields["Comment4"] !== undefined ? fields["Comment4"] : '')} 
									onChange={(e) => onHandleChange(e, 'Comment4')} 
									className={classes.inputSpace} 
									variant="outlined" />
								<TextField 
									label="Comment 5" 
									value={(fields["Comment5"] !== undefined ? fields["Comment5"] : '')} 
									onChange={(e) => onHandleChange(e, 'Comment5')} 
									className={classes.inputSpace} 
									variant="outlined" />
								<TextField 
									label="Comment 6" 
									value={(fields["Comment6"] !== undefined ? fields["Comment6"] : '')} 
									onChange={(e) => onHandleChange(e, 'Comment6')} 
									className={classes.inputSpace} 
									variant="outlined" />
								<TextField 
									label="Comment 7" 
									value={(fields["Comment7"] !== undefined ? fields["Comment7"] : '')} 
									onChange={(e) => onHandleChange(e, 'Comment7')} 
									className={classes.inputSpace} 
									variant="outlined" />
							</Grid>
						</Grid>
						<Grid container justify="flex-start" alignItems="flex-start" spacing={6}>
							<Grid item xs={12} cm={12} md={6}>
								<Typography className={classes.bottomSpaceSmall} color="textSecondary" variant="h6">
									CHOOSE FOLDER ITEMS:
								</Typography>
								<div className={classes.foldersList}>
									<RadioGroup aria-label="foldersList" name="foldersList" value={(fields.foldersList)} onChange={(e) => onHandleChange(e, 'foldersList')}>
										{renderOffersListsItems('foldersList')}
									</RadioGroup>
								</div>
							</Grid>
							<Grid item xs={12} cm={12} md={6}>
								<Typography className={classes.bottomSpaceSmall} color="textSecondary" variant="h6">
									FOLDERS LIST:
								</Typography>
								<div className={classes.foldersList}>
									{renderFolders()}
									<TextField 
										label="Folder Name" 
										value={(fields.folderName !== undefined ? fields.folderName : '')}
										onChange={(e) => onHandleChange(e, 'folderName')} 
										className={classes.input} 
										variant="outlined" 
									/>
									<Grid item className={classes.infoBlock} style={{ display: (folderErrors.length) ? 'block' : 'none' }}>
										<div className={classes.infoBlockRed}>
											{folderErrors.map((error, key) => <p key={key}>{error}</p>)}
										</div>
									</Grid>
									<Button type="button" className={classes.formBtn} variant="contained" color="secondary" onClick={onHandleCreateFolder}>CREATE</Button>
								</div>
							</Grid>
						</Grid>
						<Grid item className={classes.infoBlock} style={{ display: (errors.length) ? 'block' : 'none' }}>
							<div className={classes.infoBlockRed}>
								{errors.map((error, key) => <p key={key}>{error}</p>)}
							</div>
						</Grid>
						<Button type="submit" className={classes.formBtn} variant="contained" color="primary">CREATE NEW WEBSITE</Button>
					</form>
				</Grid>
			</Grid>
			<Grid container justify="flex-start" spacing={5}>
				<Grid item xs={12}>
					<div style={{ maxWidth: '100%' }}>
						<MaterialTable
							title = 'Manage Offers Websites'
							columns = { columns }
							data = { entries.websites }
							options = { tableOptions }
							isLoading = { loading }
							actions = {[
								{
									icon: 'add',
									tooltip: 'Create New Offers List',
									isFreeAction: true,
									onClick: () => setVisibility(!isVisible),
								},
							]}
						/>
					</div>
				</Grid>
			</Grid>
		</CardContent>
	</Card>);
};

export default withStyles(useStyles)(ManageOffersWebsitesForm);
