import React from 'react';

const Logo = (props) => {
  return (
    <img 
      alt="Logo"
      src="/static/logo-white.svg"
	  style={{width: '52px', height: '52px', margin: '0 10px 0 0'}}
      {...props}
    />
  );
};

export default Logo;
