import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography, 
  makeStyles
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import Logo from 'src/components/Logo';

const useStyles = makeStyles(() => ({
  root: {},
  toolbar: {
    height: 64
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/" style={{display:"flex", alignItems:"center"}}>
          <Logo />
		  <Typography
			className={classes.name}
			variant="h5"
			style={{color:"#fff", textTransform:"uppercase"}}
		  >
			{"Hagina Club"}
		  </Typography>
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
