import React, { useEffect, useState } from "react";
import axios from 'axios';

import {
	Input,
	InputLabel,
	FormControl,
	Radio,
	Select,
	ListItemText,
	MenuItem,
	withStyles,
} from '@material-ui/core';

const apiUrl = 'https://newsfortoday.club/webapi';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const useStyles = (theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
});

function OffersListComponent(props) {
	const { classes, id, selected, parentCallback } = props;

	const [listItems, setListItems] = useState([]);
	const [selectedListItem, setSelectedListItem] = useState(selected);

	useEffect(() => {
		axios
			.get(`${apiUrl}/?method=GetAllOffers`)
			.then((response) => {
				if (response.data.Status === 'ok') {
					let items = JSON.parse(response.data.Params);
					
					if (typeof items !== 'undefined' && items.length !== 0) {
						return items.reverse();
					} else {
						return [];
					}
				}
			})
			.then((items) => {
				setListItems(items);
			});
	}, []);

	return (
		<FormControl className={classes.formControl}>
			{
			listItems.length ?
				<>
					<InputLabel id="choose-offer-label">Choose Offer</InputLabel>
					<Select
						labelId="choose-offer-label"
						id="choose-offer"
						value={selectedListItem}
						onChange={(event) => {
							setSelectedListItem(event.target.value);
							parentCallback([id, event.target.value]);
						}}
						input={<Input />}
						renderValue={(selected) => {
							const item = listItems.find((item) => item.offerId === selected);

							return (item !== undefined) ? item.name : '';
						}}
						MenuProps={MenuProps}
						>
						{listItems.map((item) => (
							<MenuItem key={item.offerId} value={item.offerId}>
								<Radio checked={selectedListItem === item.offerId} />
								<ListItemText primary={item.name} style={{whiteSpace: 'normal'}} />
							</MenuItem>
						))}
					</Select>
				</> :
				'No Offers'
			}
		</FormControl>
	);
};

export default withStyles(useStyles)(OffersListComponent);
