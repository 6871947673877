import React from 'react';
import { Navigate } from 'react-router-dom';


import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';


// Main Pages
import HomePage from 'src/views/pages/HomePage'; // Home Page
import ManageOffers from 'src/views/pages/ManageOffers'; // Manage Offers
import ManageOffersLists from 'src/views/pages/ManageOffersLists'; // Manage Offers Lists
import ManageOffersWebsites from 'src/views/pages/ManageOffersWebsites'; // Manage Offers Websites
import ManageArticles from 'src/views/pages/ManageArticles'; // Manage Articles
import ManageArticlesLists from 'src/views/pages/ManageArticlesLists'; // Manage Articles Lists
import ManageArticlesWebsites from 'src/views/pages/ManageArticlesWebsites'; // Manage Articles Websites
import ManageSliders from 'src/views/pages/ManageSliders'; // Manage Sliders
import CreateNewDomain from 'src/views/pages/CreateNewDomain'; // Create New Domain

// Sec Pages
import NotFoundView from 'src/views/errors/NotFoundView'; // 404



const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'homepage', element: <HomePage /> },
      { path: 'manage-offers', element: <ManageOffers /> },
      { path: 'manage-offers-lists', element: <ManageOffersLists /> },
      { path: 'manage-offers-websites', element: <ManageOffersWebsites /> },
      { path: 'manage-articles', element: <ManageArticles /> },
      { path: 'manage-articles-lists', element: <ManageArticlesLists /> },
      { path: 'manage-articles-websites', element: <ManageArticlesWebsites /> },
      { path: 'manage-sliders', element: <ManageSliders /> },
      { path: 'create-new-domain', element: <CreateNewDomain /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/homepage" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
