import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';

import {
	Button,
	Card,
	CardContent,
	Grid,
	TextField,
	Typography,
	colors,
	withStyles,
} from '@material-ui/core';

const apiUrl = 'https://newsfortoday.club/webapi';

const useStyles = (theme) => ({
	infoBlock: {
		padding: '0 !important',
		fontFamily: "Roboto"
	},
	infoBlockRed: {
		border: "1px solid #ff0000",
		color: "#ff0000",
		margin: "12px 12px 20px",
		padding: "20px",
		
		"&:empty": {
			display: "none",
			visibility: "hidden",
		}
	},
	input: {
		width: "100%",
		marginBottom: "20px"
	},
	inputSpace: {
		width: "100%",
		marginBottom: theme.spacing(2)
	},
	bottomSpace: {
		marginBottom: theme.spacing(4)
	},
	bottomSpaceSmall: {
		marginBottom: theme.spacing(2)
	},
	differenceIcon: {
		color: colors.red[900]
	},
	differenceValue: {
		color: colors.red[900],
		marginRight: theme.spacing(1)
	},
	itemsList: {
		"& ul": {
			fontFamily: "Roboto",
			backgroundColor: theme.palette.background.paper,
			border: "1px solid #ccc",
			padding: theme.spacing(2) + "px " + theme.spacing(2) / 2 + "px",
			maxHeight: "535px",
			overflowY: "auto"
		}
	},
	formWrap: {
		padding: "20px",

		"& .MuiGrid-item": {
			width: "100%"
		}
	},
	createTemplateForm: {
		fontFamily: "Roboto",
		backgroundColor: theme.palette.background.paper,
		border: "1px solid #ccc",
		padding: "30px",
		marginBottom: "20px",
	},
	formBtn: {
		height: "46px",
		display: "block",
		width: "100%",
		margin: "0 0 20px",

		"& span": {
			lineHeight: "36px",
			textAlign: "center",
		}
	},
	editbtn: {
		cursor: "pointer"
	},
});

function ManageArticlesLists(props) {
	const { classes } = props;

	const [entries, setEntries] = useState({
		lists: [],
		items: [],
	});
	const [fields, setFields] = useState({
		name: '',
		articles: [],
	});
	const [errors, setErrors] = useState([]);
	const [isVisible, setVisibility] = useState(false);
	const [loading, setLoading] = useState(true);
	const [reload, setReload] = useState(false);

	const columns = [
		{
			title: 'ID',
			field: 'articlesListId',
			editable: 'never',
			readonly: true,
			align: 'left',
			headerStyle: { paddingRight: '60px' },
		},
		{
			title: 'Name',
			field: 'articlesListName',
			headerStyle: { paddingRight: '120px' },
			validate: (rowData) => (!rowData.articlesListName || rowData.articlesListName === ' ') ? 'Field can\'t be empty' : true,
		},
		{
			title: 'Articles List',
			field: 'articlesList',
			editable: 'always',
			headerStyle: { paddingRight: '200px' },
		},
		{
			title: 'Articles Count',
			field: 'articlesCounter',
			align: 'left',
			editComponent: () => (<></>),
		},
		{
			title: 'Comment 1',
			field: 'Comment1',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 2',
			field: 'Comment2',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 3',
			field: 'Comment3',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 4',
			field: 'Comment4',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 5',
			field: 'Comment5',
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Domains Count',
			field: 'domainsCounter',
			editable: 'never',
			readonly: true,
		},
	];

	const tableOptions = {
		filtering: true,
		pageSize: 5,
		pageSizeOptions: [5, 10, 20, 50, 100],
		loadingType: 'linear',
		addRowPosition: 'first',
		doubleHorizontalScroll: true,
		headerStyle: {
			fontFamily: 'Roboto',
			whiteSpace: 'nowrap',
		},
		rowStyle: {
			fontFamily: 'Roboto',
		},
	};

	const itemsOrderHandler = (a, b) => {
		const aOrder = a.orderNumber;
		const bOrder = b.orderNumber;

		if (typeof aOrder !== 'number' && typeof bOrder === 'number') {
			return -1;
		} else if (typeof aOrder !== 'number') {
			return -1;
		} else if (typeof bOrder !== 'number') {
			return 1;
		}

		if (aOrder > bOrder) {
			return 1;
		} else if (aOrder < bOrder) {
			return -1;
		}

		return 0;
	};

	const orderPlaceholder = (<div style={{ opacity: '0' }}>- - -</div>);

	const innerColumns = [
		{
			title: 'ID',
			field: 'articleId',
			editable: 'never',
			readonly: true,
			align: 'left',
			headerStyle: { paddingRight: '60px' },
		},
		{
			title: 'Order',
			field: 'orderNumber',
			type: 'numeric',
			editable: 'always',
			// sorting: false,
			align: 'left',
			headerStyle: { paddingRight: '100px' },
			render: (rowData) => (rowData.orderNumber !== '') ? rowData.orderNumber : orderPlaceholder,
			// render: (rowData) => (rowData.orderNumber !== '') ?
			// 	(<div style={{ display: (rowData.tableData.checked ? 'block' : 'none') }}>{rowData.orderNumber}</div>) :
			// 	(<div style={{ opacity: '0', display: (rowData.tableData.checked ? 'block' : 'none') }}>'- - -'</div>),
			customSort: itemsOrderHandler,
		},
		{
			title: 'Title',
			field: 'name',
			editable: 'never',
			readonly: true,
			headerStyle: { paddingRight: '120px' },
			validate: (rowData) => (!rowData.name || rowData.name === ' ') ? 'Field can\'t be empty' : true,
		},
		{
			title: 'Text',
			field: 'text',
			editable: 'never',
			readonly: true,
			headerStyle: { paddingRight: '150px' },
			validate: (rowData) => (!rowData.text || rowData.text === ' ') ? 'Field can\'t be empty' : true,
		},
		{
			title: 'Link',
			field: 'link',
			editable: 'never',
			readonly: true,
			headerStyle: { paddingRight: '150px' },
			validate: (rowData) => (!rowData.link || rowData.link === ' ') ? 'Field can\'t be empty' : true,
		},
		{
			title: 'Link Name',
			field: 'linkName',
			editable: 'never',
			readonly: true,
			headerStyle: { paddingRight: '100px' },
			validate: (rowData) => (!rowData.linkName || rowData.linkName === ' ') ? 'Field can\'t be empty' : true,
		},
		{
			title: 'Comment 1',
			field: 'Comment1',
			editable: 'never',
			readonly: true,
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 2',
			field: 'Comment2',
			editable: 'never',
			readonly: true,
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 3',
			field: 'Comment3',
			editable: 'never',
			readonly: true,
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 4',
			field: 'Comment4',
			editable: 'never',
			readonly: true,
			headerStyle: { paddingRight: '120px' },
		},
		{
			title: 'Comment 5',
			field: 'Comment5',
			editable: 'never',
			readonly: true,
			headerStyle: { paddingRight: '120px' },
		},
	];

	const innerTableOptions = {
		filtering: true,
		pageSize: 5,
		pageSizeOptions: [5, 10, 20, 50, 100],
		loadingType: 'linear',
		doubleHorizontalScroll: true,
		selection: true,
		headerStyle: {
			fontFamily: 'Roboto',
			whiteSpace: 'nowrap',
		},
		rowStyle: {
			fontFamily: 'Roboto',
		},
	};

	useEffect(() => {
		const fetchData = async () => {
			const lists = await axios.get(`${apiUrl}/?method=GetAllArticlesLists`);
			const items = await axios.get(`${apiUrl}/?method=GetAllArticles`);

			const newData = {
				lists: {
					data: (lists.data.Status === 'ok') ? JSON.parse(lists.data.Params).reverse() : [],
					fields: [
						'articlesListName',
						'Comment1',
						'Comment2',
						'Comment3',
						'Comment4',
						'Comment5',
					],
				},
				items: {
					data: (items.data.Status === 'ok') ? JSON.parse(items.data.Params).reverse() : [],
					fields: [
						'name',
						'text',
						'link',
						'linkName',
						'Comment1',
						'Comment2',
						'Comment3',
						'Comment4',
						'Comment5',
					],
				},
			};

			Object.entries(newData).forEach(([key, data]) => {
				if (!data.data.length) {
					return;
				}

				const newDataArray = data.data.map((dataRow) => {
					data.fields.forEach((field) => {
						if (dataRow[field] === null) {
							dataRow[field] = '';
						}
					});

					if (key === 'lists') {
						if (dataRow.articles === null) {
							dataRow.articles = [];
						}

						dataRow.articlesCounter = dataRow.articles.length;
						dataRow.articlesList = dataRow.articles.map((innerItem) => innerItem.name).join(', ');
					} else {
						if (dataRow.orderNumber === undefined) {
							dataRow.orderNumber = '';
						}
					}

					return dataRow;
				});

				newData[key].data = newDataArray;
			});

			setEntries({
				lists: newData.lists.data,
				items: newData.items.data,
			});

			setLoading(false);
		};

		fetchData();
	}, [reload]);

	const onHandleChange = (e, field) => {
		setFields({ ...fields, [field]: e.target.value });
	};

	const onHandleSubmit = async (e) => {
		e.preventDefault();

		const fieldsErrors = [];

		if (!fields.name.trim().length) {
			fieldsErrors.push('Articles List Name is empty');
		}
		
		if (!fields.articles.length) {
			fieldsErrors.push('Articles not selected');
		}

		if (!fieldsErrors.length) {
			const noOrderArticles = fields.articles
				.filter((item) => typeof item.orderNumber !== 'number')
				.map((item) => item.articleId);
			const orderArticles = fields.articles
				.filter((item) => typeof item.orderNumber === 'number')
				.sort(itemsOrderHandler)
				.map((item) => item.articleId);
			const articlesList = orderArticles.concat(noOrderArticles);

			const createWebsite = async () => {
				const responseData = {
					name: fields.name.trim(),
					articlesIds: articlesList,
					Comment1: fields.Comment1,
					Comment2: fields.Comment2,
					Comment3: fields.Comment3,
					Comment4: fields.Comment4,
					Comment5: fields.Comment5,
				};

				if (fields.itemId !== undefined) {
					responseData.articlesListId = fields.itemId;
				}

				const response = await axios.post(`${apiUrl}/?method=${(fields.itemId === undefined) ? 'Insert' : 'Edit'}ArticlesList`, responseData);

				console.log(response.data);

				if (response.data.Status === 'ok') {
					setFields({
						name: '',
						articles: [],
					});
					setErrors([]);
					setVisibility(false);
					setLoading(true);
					setReload(!reload);
				} else {
					setErrors(['Request Error']);
				}
			};

			createWebsite();
		}

		setErrors(fieldsErrors);
	};

	const addNewRowHandler = () => {
		const visibile = isVisible;

		setVisibility(!isVisible);

		if (visibile) {
			setFields({
				name: '',
				articles: [],
				Comment1: '',
				Comment2: '',
				Comment3: '',
				Comment4: '',
				Comment5: '',
			});

			const newItems = entries.items.map((item) => {
				if (!item.tableData) {
					item.tableData = {};
				}

				if (item.orderNumber !== orderPlaceholder) {
					item.orderNumber = orderPlaceholder;
				}

				item.tableData.checked = false;

				return item;
			});

			setEntries({ ...entries, items: newItems });
		}
	};

	const editRowHandler = (event, rowData) => {
		let newArticles = rowData.articles;

		if (rowData.articles.length) {
			const subItemsArray = rowData.articles.map((subItem) => subItem.articleId);

			newArticles = rowData.articles.map((article) => {
				article.orderNumber = subItemsArray.indexOf(article.articleId) + 1;

				return article;
			});

			const newItems = entries.items.map((item) => {
				if (!item.tableData) {
					item.tableData = {};
				}

				if (item.orderNumber !== orderPlaceholder) {
					item.orderNumber = orderPlaceholder;
				}

				item.tableData.checked = false;

				if (subItemsArray.includes(item.articleId)) {
					item.tableData.checked = true;

					item.orderNumber = subItemsArray.indexOf(item.articleId) + 1;
				}

				return item;
			});

			setEntries({ ...entries, items: newItems });
		}

		setFields({
			name: rowData.articlesListName,
			articles: newArticles,
			Comment1: rowData.Comment1,
			Comment2: rowData.Comment2,
			Comment3: rowData.Comment3,
			Comment4: rowData.Comment4,
			Comment5: rowData.Comment5,
			itemId: rowData.articlesListId,
		});

		setVisibility(true);
	};

	const deleteRowHandler = (oldData) => new Promise((resolve) => {
		setTimeout(() => {
			const data = [...entries.lists];

			data.splice(data.indexOf(oldData), 1);

			axios
				.post(`${apiUrl}/?method=DeleteArticlesList`, { articlesListId: oldData.articlesListId })
				.then((res) => {
					console.log(res.data);

					if (res.data.Status === 'ok') {
						setReload(!reload);
					}
				});

			setEntries({ ...entries, lists: data });

			resolve();
		}, 200);
	});

	const selectRowHandler = (rows) => setFields({ ...fields, articles: rows });

	const orderChangeHandler = (newValue, oldValue, rowData) => new Promise((resolve, reject) => {
		setTimeout(() => {
			if (rowData.orderNumber === oldValue) {
				rowData.orderNumber = Number(newValue);
			}

			const newArticles = fields.articles.map((article) => {
				if (article.articleId === rowData.articleId) {
					article.orderNumber = rowData.orderNumber;
				}

				return article;
			});

			setFields({ ...fields, articles: newArticles })

			resolve();
		}, 200);
	});

	return ( <Card>
		<CardContent>
			<Grid container justify="flex-start" spacing={5} style={{display: (isVisible ? 'block' : 'none')}}>
				<Grid item xs={12}>
					<form className={classes.createTemplateForm} noValidate autoComplete="off" onSubmit={onHandleSubmit}>
						<Grid container justify="flex-start" alignItems="center" spacing={6} className={classes.bottomSpaceSmall}>
							<Grid item md={12} style={{ textAlign: 'right' }}>
								<Button variant="contained" color='default' onClick={addNewRowHandler}>CANCEL</Button>
							</Grid>
						</Grid>
						<TextField
							label="Articles List Name"
							value={fields.name}
							onChange={(e) => onHandleChange(e, 'name')} 
							className={classes.input} 
							variant="outlined" 
						/>
						<div style={{ maxWidth: '100%' }} className={classes.bottomSpace}>
							<MaterialTable
								title = 'Choose Articles'
								columns = { innerColumns }
								data = { entries.items }
								options = { innerTableOptions }
								isLoading = { loading }
								onSelectionChange = { selectRowHandler }
								cellEditable = {{
									cellStyle: { minWidth: '100px' },
									onCellEditApproved: orderChangeHandler,
								}}
							/>
						</div>
						<div style={{ maxWidth: '100%' }} className={classes.bottomSpace}>
							<Typography className={classes.bottomSpaceSmall} color="textSecondary" variant="h6">ADD COMMENTS:</Typography>
							<TextField 
								label="Comment 1" 
								value={(fields["Comment1"] !== undefined ? fields["Comment1"] : '')} 
								onChange={(e) => onHandleChange(e, 'Comment1')} 
								className={classes.inputSpace} 
								variant="outlined" />
							<TextField 
								label="Comment 2" 
								value={(fields["Comment2"] !== undefined ? fields["Comment2"] : '')} 
								onChange={(e) => onHandleChange(e, 'Comment2')} 
								className={classes.inputSpace} 
								variant="outlined" />
							<TextField 
								label="Comment 3" 
								value={(fields["Comment3"] !== undefined ? fields["Comment3"] : '')} 
								onChange={(e) => onHandleChange(e, 'Comment3')} 
								className={classes.inputSpace} 
								variant="outlined" />
							<TextField 
								label="Comment 4" 
								value={(fields["Comment4"] !== undefined ? fields["Comment4"] : '')} 
								onChange={(e) => onHandleChange(e, 'Comment4')} 
								className={classes.inputSpace} 
								variant="outlined" />
							<TextField 
								label="Comment 5" 
								value={(fields["Comment5"] !== undefined ? fields["Comment5"] : '')} 
								onChange={(e) => onHandleChange(e, 'Comment5')} 
								className={classes.inputSpace} 
								variant="outlined" />
						</div>
						<Grid item className={classes.infoBlock} style={{ display: (errors.length) ? 'block' : 'none' }}>
							<div className={classes.infoBlockRed}>
								{errors.map((error, key) => <p key={key}>{error}</p>)}
							</div>
						</Grid>
						<Button type="submit" className={classes.formBtn} variant="contained" color="primary">
							{(fields.itemId === undefined) ? 'CREATE NEW' : 'UPDATE'} ARTICLES LIST
						</Button>
					</form>
				</Grid>
			</Grid>
			<Grid container justify="flex-start" spacing={5}>
				<Grid item xs={12}>
					<div style={{ maxWidth: '100%' }}>
						<MaterialTable
							title = 'Manage Articles Lists'
							columns = { columns }
							data = { entries.lists }
							options = { tableOptions }
							isLoading = { loading }
							editable = {{
								onRowDelete: deleteRowHandler,
							}}
							actions = {[
								{
									icon: 'add',
									tooltip: 'Create New Articles List',
									isFreeAction: true,
									onClick: addNewRowHandler,
								},
								{
									icon: 'edit',
									tooltip: 'Edit Articles List',
									onClick: editRowHandler,
								},
							]}
						/>
					</div>
				</Grid>
			</Grid>
		</CardContent>
	</Card>);
};

export default withStyles(useStyles)(ManageArticlesLists);
