import React, {Component} from 'react';
import axios from 'axios';

import {
	Card,
	CardContent,
	Grid,
	Typography,
	Button,
	TextField,
	List,
	ListItem,
	ListItemText, 
	ListItemAvatar, 
	Avatar, 
	withStyles
} from '@material-ui/core';

import DnsIcon from '@material-ui/icons/Dns';

const WAIT_INTERVAL = 1000;

const useStyles = theme => ({
	root: {
		height: '100%',
	},
	input: {
		width: "100%", 
		marginBottom: "20px"
	}, 
	bottomSpace: {
		marginBottom: theme.spacing(4)
	},
	formWrap: {
		minHeight: "600px", 
		padding: "30px", 
		"& .MuiGrid-item": {
			width: "100%"
		}
	},
	formBtn: {
		height: "46px",
		display: "block",
		width: "100%",
		margin: "0 0 20px",

		"& span": {
			lineHeight: "36px", 
			textAlign: "center",
		}
	},
	domainsList: {
		"& ul": {
			fontFamily: "Roboto", 
			backgroundColor: theme.palette.background.paper,
			border: "1px solid #ccc",
			padding: theme.spacing(2) + "px " + theme.spacing(2) / 2 + "px",
			maxHeight: "535px",
			overflowY: "auto"
		}, 
		"& ul li": {
			flexWrap: "wrap"
		},
		"& ul li > div:first-child": {
			width: "5%"
		}, 
		"& ul li > div": {
			width: "25%"
		}, 
		"& ul li > div:last-child > span:not(:empty)": {
			padding: "12px 0 20px", 
			borderBottom: "1px solid #ccc", 
			marginBottom: "20px"
		}
	},
	domainStatusPending: {
		color: "orange",
		fontWeight: "bold",
	}, 
	domainStatusSuccess: {
		color: "green",
		fontWeight: "bold",
	}, 
	domainStatusFailed: {
		color: "red",
		fontWeight: "bold",
	}, 
	domainErrorStrEmpty : {
		width: "100%"
	}, 
	domainErrorStr : {
		width: "100%"
	}
});

class CreateNewDomain extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			currentState: 0,
			domainName: '',
			domainReserved: '',
			domainError: '',
			domains: [],
		};

		this.onHandleChange = this.onHandleChange.bind(this);
	}
	
	// Load
	componentDidMount() {
		this.timer = null;

		try {
			this.getDomains();
		} catch (error) {
			this.setState({isLoaded: true, currentState: 666});
		}
	}
	
	// Requests
	async getDomains() {
		const response = await axios.post('https://newsfortoday.club/webapi/?method=GetAllDomains');
		if (response.data.Status === "ok") {
			let domains = JSON.parse(response.data.Params);
			
			if (typeof domains !== 'undefined' && domains.length !== 0) {
				this.setState({isLoaded: true, domains: domains.reverse()});
			} else {
				this.setState({isLoaded: true, domains: []});
			}
		} else {
			this.setState({isLoaded: true, domains: [], currentState: 666});
		}
	}
	
	async checkDomain() {
		let domainName = this.state.domainName;
		const response = await axios.post('https://newsfortoday.club/webapi/?method=CheckDomainAvailability', {"domain": domainName});

		if (response.data.Status === "ok" && response.data.Params === "true") {
			this.setState({
				domainReserved: domainName, 
				currentState: 2
			});
		} else {
			this.setState({
				domainReserved: '', 
				currentState: 3
			});
		}
	}

	// Handles
	onHandleChange(e) {
		let domainName = e.target.value;
		let domainError = this.state.domainError;

		this.setState({
			domainName: domainName
		});

		if (e.target.value !== '') {
			clearTimeout(this.timer);

			this.setState({
				domainError: "",
				currentState: 1,
			});
			this.timer = setTimeout(this.triggerChange.bind(this), WAIT_INTERVAL);
		} else {
			domainError = "Field is Empty";
			this.setState({
				domainError: domainError, 
				currentState: 666,
			});
		}
	}

	triggerChange() {
		this.setState({currentState: 1}); this.checkDomain();
	}

	onHandleAddNewDomainSubmit = () => async e => {
		e.preventDefault();		
		const {currentState, domainReserved, domainName} = this.state;

		if (domainName === domainReserved) {
			this.setState({ currentState: 999 }); // Set Loading

			if (currentState === 2) { // Run Register Domain
				try {
					const response = await axios.post('https://newsfortoday.club/webapi/?method=InsertDomain', {"domainName": domainReserved});

					if (response.data.Status === "ok") {
						this.getDomains();
						this.setState({ currentState: 4 });

						setTimeout(() => {
							this.setState({ currentState: 0, domainName: '' })
						}, 2000);
					} else {
						this.setState({ currentState: 666 });
					}
				} catch (error) {
					
				}
			}
		} else {
			if (currentState !== 3) {
				this.setState({
					currentState: 666,
				});
			}
		}
	}

	// Renders	
	renderFormButtons() {
		const {classes} = this.props;
		const {currentState} = this.state;

		let btnText 	= 'CHECKING';
		let btnColor 	= 'primary';

		if (currentState === 0) {
			btnText 	= 'ENTER A DOMAIN NAME';
			btnColor 	= 'primary';
		} else if (currentState === 1) {
			btnText 	= 'CHECKING';
			btnColor 	= 'primary';
		} else if (currentState === 2) {
			btnText 	= 'CREATE NEW DOMAIN';
			btnColor 	= 'primary';
		} else if (currentState === 3) {
			btnText 	= 'NOT AVAILABLE';
			btnColor 	= 'secondary';
		} else if (currentState === 4) {
			btnText 	= 'DONE';
			btnColor 	= 'primary';
		} else if (currentState === 666) {
			btnText 	= 'ERROR';
			btnColor 	= 'secondary';
		}

		return(<div><Button type="button" onClick={this.onHandleAddNewDomainSubmit()} className={classes.formBtn} variant="contained" color={btnColor}>{btnText}</Button></div>);
	}

	renderDomains(isLoaded, domains) {
		const {classes} = this.props;

		if (isLoaded && domains.length !== 0) {
			return domains.map(domain => {
				let domainStatus = "Pending";
				let domainStatusClass = classes.domainStatusPending;
				let errorStr = '';

				if (domain.isFinished === true) {
					domainStatus = 'Success';
					domainStatusClass = classes.domainStatusSuccess;
				} else if (domain.isFailed === true) {
					domainStatus = 'Failed';
					domainStatusClass = classes.domainStatusFailed;

					if (domain.errorStr) {
						errorStr = domain.errorStr;
					}
				}
				
				return(<ListItem key={domain.domainId}>
					<ListItemAvatar>
						<Avatar>
							<DnsIcon/>
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary={this.convertDate(domain.addedDate)}/>
					<ListItemText primary={domain.domainName}/>
					<ListItemText classes={{ primary: domainStatusClass }} primary={domainStatus}/>
					<ListItemText primary={errorStr}/>
					
				</ListItem>)
			})
		} else {
			return <ListItem>No Domains</ListItem>
		}
	}
	
	// Other
	convertDate(inputFormat) {
		function pad(s) { return (s < 10) ? '0' + s : s; }
		var d = new Date(inputFormat)
		return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
	}

	// View
	render() {
		const {classes} = this.props;
		const {isLoaded, domainName, domainError, domains} = this.state;

		return (<Card>
			<CardContent>
				<Grid container justify="flex-start" spacing={5} className={classes.formWrap}>
					<Grid item xs={12} cm={12} md={12}>
						<Typography className={classes.bottomSpace} color="textSecondary" variant="h5">
							CREATE NEW DOMAIN:
						</Typography>
						<Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={3}>
							<Grid item>
								<form className={classes.root}>
									<TextField
										label="Domain Name"
										value={domainName}
										error={domainError !== '' ? true : false}
										helperText={domainError !== '' ? domainError : ''}
										onChange={this.onHandleChange}
										className={classes.input} 
										variant="outlined" 
									/>
									{this.renderFormButtons()}
								</form>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} cm={12} md={12}>
						<div className={classes.domainsList}>
							<Typography className={classes.bottomSpace} color="textSecondary" variant="h5">
								DOMAINS:
							</Typography>
							<List>
								{this.renderDomains(isLoaded, domains)}
							</List>
						</div>
					</Grid>
				</Grid>
			</CardContent>
		</Card>);
	}
};

export default withStyles(useStyles)(CreateNewDomain);
