import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  makeStyles
} from '@material-ui/core';

import HomeIcon from '@material-ui/icons/Home';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DomainIcon from '@material-ui/icons/Domain';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import DescriptionIcon from '@material-ui/icons/Description';

import NavItem from './NavItem';

const items = [
  {
    href: '/app/homepage',
    icon: HomeIcon,
    title: 'Home Page', 
	  divider: true
  },
  {
    href: '/app/manage-offers',
    icon: LocalOfferIcon,
    title: 'Manage Offers'
  },
  {
    href: '/app/manage-offers-lists',
    icon: LocalOfferIcon,
    title: 'Manage Offers Lists',
  },
  {
    href: '/app/manage-offers-websites',
    icon: InsertDriveFileIcon,
    title: 'Manage Offers Websites',
  	divider: true
  },
  {
    href: '/app/manage-articles',
    icon: DescriptionIcon,
    title: 'Manage Articles'
  },
  {
    href: '/app/manage-articles-lists',
    icon: DescriptionIcon,
    title: 'Manage Articles Lists'
  },
  {
    href: '/app/manage-articles-websites',
    icon: InsertDriveFileIcon,
    title: 'Manage Articles Websites',
  	divider: true
  },
  {
    href: '/app/manage-sliders',
    icon: DescriptionIcon,
    title: 'Manage Sliders',
    divider: true
  },
  {
    href: '/app/create-new-domain',
    icon: DomainIcon,
    title: 'Create New Domain', 
    divider: true
  },
];

const useStyles = makeStyles(() => ({
	mobileDrawer: {
		width: 280
	},
	desktopDrawer: {
		width: 280,
		top: 64,
		height: 'calc(100% - 64px)'
	},
	avatar: {
		cursor: 'pointer',
		width: 64,
		height: 64
	}
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box p={2}>
        <List>
			{items.map(item => {
				return(
					<NavItem
						href={item.href}
						key={item.title}
						title={item.title}
						icon={item.icon}
						divider={item.divider}
					/>
				);
			})}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
