import React from 'react';
import {Container, makeStyles} from '@material-ui/core';
import Page from 'src/components/Page';
import CreateNewDomainForm from './CreateNewDomainForm';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: '100%',
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3), 
	}, 
	wrap: {
		width: "1200px", 
		maxWidth: "100%", 
	}
}));

const CreateNewDomain = () => {
	const classes = useStyles();

	return (<Page className={classes.root} title="Manage Offers">
		<Container className={classes.wrap}>
			<CreateNewDomainForm/>
		</Container>
	</Page>);
};

export default CreateNewDomain;
